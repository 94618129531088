<template>
  <div class="book-video">
    <div class="wrapper">
      <img class="image" :src="book.imageUrl" />
      <p class="title">{{ `ملخص ${book.title} - ${book.author}` }}</p>
      <a data-fancybox :href="book.summeryVideoUrl" class="fancybox video-button">
        <span class="action">
          <i class="fa fa-play"></i>
        </span>
      </a>
    </div>
    <div class="book-title">
      <img :src="book.imageUrl" />
      <p>{{ `${book.title}` }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    book: {
      type: Object,
      default: () => null,
    },
  },
};
</script>

<style scoped>
.book-video {
  width: 100%;
}

.book-video .wrapper {
  position: relative;
  width: 100%;
  height: 420px;
  border-radius: 25px;
  box-shadow: 3px 9px 8px rgba(106, 106, 106, 16%);
}

.book-video .wrapper::after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg,
      rgba(157, 204, 195, 0.1) 0%,
      rgba(71, 206, 198, 0.9) 100%);
  border-radius: 25px;
}

.book-video .wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 25px;
}

.book-video .wrapper .title {
  font-size: 22px;
  font-weight: 500;
  color: rgb(255, 255, 255);
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 0px 40px 20px 0px;
  z-index: 1;
  width: 100%;
}

.book-video .book-title {
  display: flex;
  align-items: center;
  column-gap: 20px;
  padding: 25px 10px 0px 0px;
}

.book-video .book-title p {
  font-size: 18px;
  font-weight: 600;
}

.book-video .book-title img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  box-shadow: 3px 9px 8px rgba(106, 106, 106, 16%);
}

.book-video .action {
  background: #ffffff;
  border-radius: 50%;
  padding: 6px;
  font-size: 16px;
  width: 90px;
  height: 90px;
  transition: all 0.2s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  z-index: 1;
  cursor: pointer !important;
}

.book-video .action i {
  color: #f4acbf;
  font-size: 50px;
}

.book-video .action:hover {
  transform: translate(50%, -50%) scale(1.1);
}

@media (max-width: 767px) {
  .book-video .wrapper {
    height: 300px;
  }

  .book-video .wrapper .title {
    font-size: 17px;
    font-weight: 600;
    text-align: center;
    padding: 0px 15px 15px 15px;
  }

  .book-video .book-title p {
    font-size: 16px;
    font-weight: 600;
  }

  .book-video .action {
    width: 75px;
    height: 75px;
  }

  .book-video .action i {
    font-size: 40px;
  }
}
</style>