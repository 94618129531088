<template>
  <div class="zadi-home-page">
    <hero-section />
    <div class="wrapper">
      <recent-magazines-section />
      <recent-book-section />
      <!-- <recent-book-video-section /> -->
    </div>
  </div>
</template>

<script>
import HeroSection from "../components/home/HeroSection.vue";
import RecentMagazinesSection from "../components/home/RecentMagazinesSection.vue";
import RecentBookSection from "../components/home/RecentBookSection.vue";

export default {
  metaInfo: {
    title: "مشروع زادي | الرئيسية ",
  },
  components: { HeroSection, RecentMagazinesSection, RecentBookSection },
};
</script>

<style scoped>
.zadi-home-page {
  padding: 0px 0px 71px 0px;
}

.wrapper {
  padding: 0px 40px 0px 40px;
}

@media (max-width: 560px) {
  .wrapper {
    padding: 0px 20px 0px 20px;
  }
}
</style>
