import { httpClient } from "../../shared/services";

const { VUE_APP_ZADI_API_BASE_URL } = process.env
const zadiApiBaseUrl = VUE_APP_ZADI_API_BASE_URL

export default {
  getAllMagazines(page = 1, limit = 8) {
    const url = `${zadiApiBaseUrl}v1/magazines?sort=releaseNumber,DESC&page=${page}&limit=${limit}`
    return httpClient.get(url)
  },
  getRecentMagazines() {
    const url = `${zadiApiBaseUrl}v1/magazines?sort=releaseNumber,DESC&page=1&limit=8`
    return httpClient.get(url)
  },
}
