import { httpClient } from "../../shared/services";

const { VUE_APP_ZADI_API_BASE_URL } = process.env
const zadiApiBaseUrl = VUE_APP_ZADI_API_BASE_URL

export default {
  getAllBooks(page = 1, limit = 4) {
    const url = `${zadiApiBaseUrl}v1/books?sort=id,DESC&page=${page}&limit=${limit}`
    return httpClient.get(url)
  },
  getBookById(id) {
    const url = `${zadiApiBaseUrl}v1/books/${id}`
    return httpClient.get(url)
  },
  getRecentBook() {
    const url = `${zadiApiBaseUrl}v1/books?sort=id,DESC&page=1&limit=1`
    return httpClient.get(url)
  },
}
