var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "book-video"
  }, [_c('div', {
    staticClass: "wrapper"
  }, [_c('img', {
    staticClass: "image",
    attrs: {
      "src": _vm.book.imageUrl
    }
  }), _c('p', {
    staticClass: "title"
  }, [_vm._v(_vm._s(("ملخص " + (_vm.book.title) + " - " + (_vm.book.author))))]), _c('a', {
    staticClass: "fancybox video-button",
    attrs: {
      "data-fancybox": "",
      "href": _vm.book.summeryVideoUrl
    }
  }, [_vm._m(0)])]), _c('div', {
    staticClass: "book-title"
  }, [_c('img', {
    attrs: {
      "src": _vm.book.imageUrl
    }
  }), _c('p', [_vm._v(_vm._s(("" + (_vm.book.title))))])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "action"
  }, [_c('i', {
    staticClass: "fa fa-play"
  })]);
}]

export { render, staticRenderFns }