var render = function () {
  var _vm$magazine;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "magazine-card"
  }, [_c('div', {
    staticClass: "content",
    on: {
      "click": function ($event) {
        return _vm.onOpenMagazine();
      }
    }
  }, [_vm._m(0), _c('div', {
    staticClass: "text"
  }, [_c('span', [_vm._v("مجلة زادي | العدد " + _vm._s((_vm$magazine = _vm.magazine) === null || _vm$magazine === void 0 ? void 0 : _vm$magazine.releaseNumber))])])]), _c('div', {
    staticClass: "actions"
  }, [_c('button', {
    on: {
      "click": _vm.onShareMagazine
    }
  }, [_c('img', {
    attrs: {
      "src": require("../../../../../public/assets/images/website/zadi/share.png")
    }
  }), _vm._v(" مشاركة العدد ")])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "image"
  }, [_c('img', {
    attrs: {
      "src": require("../../../../../public/assets/images/website/zadi/magazine-cover.jpg")
    }
  })]);
}]

export { render, staticRenderFns }