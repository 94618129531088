<template>
  <div class="book-summary">
    <div class="row">
      <div class="col-lg-3">
        <div class="image">
          <img :src="recentBook?.imageUrl" />
        </div>
        <div class="info">
          <p>{{ recentBook?.title }}</p>
          <p>{{ recentBook?.author }}</p>
        </div>
      </div>
      <div class="col-lg-9">
        <div class="details">
          <p>{{ recentBook?.description }}</p>
        </div>
        <div class="actions">
          <button @click="onShareRecentBook">
            <img src="../../../../../public/assets/images/website/zadi/share.png" />
            مشاركة الملخص
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    recentBook: {
      type: Object,
      default: () => null,
    },
  },
  methods: {
    onShareRecentBook() {
      this.$emit('share-recent-book')
    },
  },
};
</script>

<style scoped>
.book-summary {
  width: 100%;
  min-height: 380px;
  border-radius: 25px;
  border: 2px solid #86ccd4;
  padding: 22px;
  box-shadow: 3px 9px 8px rgba(106, 106, 106, 16%);
}

.col-lg-9 {
  display: flex;
  flex-direction: column;
}

.image img {
  border-radius: 16px;
  border: 2px solid #86ccd4;
  width: 100%;
  height: 280px;
  object-fit: cover;
}

.info {
  font-weight: 500;
  font-size: 17px;
  display: flex;
  align-items: center;
  flex-direction: column;
  row-gap: 5px;
  margin-top: 10px;
}

.info p:first-child {
  font-weight: 600;
  color: #86ccd4;
  font-size: 22px;
  text-align: center;
}

.info p:last-child {
  font-weight: 500;
  font-size: 18px;
  text-align: center;
}

.details {
  flex: 1;
  margin-bottom: 28px;
}

.details p {
  font-weight: 500;
  font-size: 17px;
  overflow-y: auto;
  height: 285px;
  padding-inline-end: 15px;
  text-align: justify;
}

.actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.actions button {
  background: #f4acbf;
  border-radius: 10px;
  color: #ffffff;
  padding: 6px;
  font-size: 16px;
  width: 210px;
  font-weight: 500;
  transition: all 0.2s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 8px;
}

.actions button img {
  width: 25px;
}

.actions button:hover {
  background: #f58da9;
}

@media (max-width: 992px) {
  .image {
    display: flex;
    justify-content: center;
  }

  .info {
    margin-bottom: 30px !important;
    text-align: justify !important;
  }

  .details p {
    min-height: 285px !important;
  }

  .actions {
    justify-content: center;
  }
}
</style>